import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import BottomButton from "src/components/Button/BottomButton";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import {
  H3Bold,
  Body1Regular,
  Body1RegularPre,
  Body1BoldSpan,
} from "src/components/Typography/Typography";

export type Props = {
  className?: string;
  email: string;
  onSubmit: () => void;
} & WithTranslation;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(MaterialIcon)`
  margin-right: 11px;
`;

const Title = styled(H3Bold)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const HighlightDescription = styled(Body1BoldSpan)`
  color: ${palette.primary.main};
`;

const RequestResetPasswordEmailForm = (props: Props) => {
  const { className, email, onSubmit } = props;

  return (
    <MaterialForm
      className={className}
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>
            <CheckIcon
              name="check_circle"
              fontSize={30}
              color={palette.green.main}
            />
            이메일이 전송되었습니다.
          </Title>
          <Description>
            <HighlightDescription>{email}</HighlightDescription>
            {`로 비밀번호 재설정 이메일이 전송되었습니다. 메일함을 확인해 주세요.`}
          </Description>
        </Header>
      }
      FooterComponent={
        <BottomButton
          buttonName={"확인"}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: onSubmit,
            isLoading: false,
          }}
        />
      }
    />
  );
};

export default withTranslation()(RequestResetPasswordEmailForm);
