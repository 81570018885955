import { makeStyles } from "@mui/styles";
import React from "react";
import styled from "@emotion/styled";

import MaterialAppbar, {
  appbarHeight,
} from "src/components/Appbar/MaterialAppbar";
import StepIndicator from "src/components/Indicator/StepIndicator";
import { spacing } from "src/themes/spacing";
import { Dialog, Slide, SlideProps, DialogProps } from "@mui/material";
import { Props as StepIndicatorItemProps } from "src/components/Indicator/StepIndicatorItem";
import { palette } from "src/themes/palette";

type Props = DialogProps & {
  open: DialogProps["open"];
  children: React.ReactChild;
  appBarLeftComponent: React.ReactNode;
  appBarRightComponent: React.ReactNode;
  indicatorData: StepIndicatorItemProps[];
  onClose: () => void;
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background-color: ${palette.lightgrey.main};
  padding-top: 0px;
`;

const Appbar = styled(MaterialAppbar)`
  position: fixed;
  background-color: ${palette.lightgrey.main};
`;

const Content = styled.div`
  z-index: 11;
  margin: auto 0px;
`;

const HideTopbar = styled.div`
  min-height: 72px;
`;

const HideBottombar = styled.div`
  min-height: 96px;
`;

const Indicator = styled.div`
  position: fixed;
  left: 0px;
  margin-top: ${`calc(${spacing.small5} + ${appbarHeight}px)`};
  margin-left: ${spacing.small5};
  top: 0px;
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    background-color: ${palette.lightgrey.main};
  }
`;

const Transition = React.forwardRef<unknown, SlideProps>(
  function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
  },
);

function StepByStepLayout({
  children,
  appBarLeftComponent,
  appBarRightComponent,
  indicatorData,
  open = false,
  onClose,
  ...rest
}: Props) {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      {...rest}
    >
      <Container>
        <Appbar
          left={appBarLeftComponent}
          right={appBarRightComponent}
          noPaddingRight={true}
        />
        <Indicator>
          <StepIndicator data={indicatorData} />
        </Indicator>
        <Content>
          <HideTopbar />
          {children}
          <HideBottombar />
        </Content>
      </Container>
    </StyledDialog>
  );
}

export default StepByStepLayout;
