import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { palette } from "src/themes/palette";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import { H6Bold } from "src/components/Typography/Typography";
import { shouldForwardProp } from "src/themes/config";

type StatusType = "normal" | "visited" | "active";

export type Props = {
  status: StatusType;
  stepNumber: string;
  name: string;
};

const Container = styled(H6Bold, {
  shouldForwardProp,
})<{ $status: StatusType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $status }) =>
    $status === "active"
      ? css`
          color: ${palette.primary.main};
        `
      : css`
          color: ${palette.bluegrey.sub};
        `}
`;

const StepNumber = styled(H6Bold, {
  shouldForwardProp,
})<{ $status: StatusType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  margin-right: 12px;
  ${({ $status }) =>
    $status === "active"
      ? css`
          color: #fff;
          background-color: ${palette.primary.main};
        `
      : css`
          color: ${palette.bluegrey.sub};
          background-color: ${palette.bluegrey.pale};
        `}
`;

const StepIndicatorItem = (props: Props) => {
  const { status, stepNumber, name } = props;
  return (
    <Container $status={status}>
      <StepNumber $status={status}>
        {status === "visited" ? (
          <MaterialIcon
            name="check"
            fontSize={16}
            color={palette.bluegrey.sub}
          />
        ) : (
          stepNumber
        )}
      </StepNumber>
      {name}
    </Container>
  );
};

export default StepIndicatorItem;
