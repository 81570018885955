import React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { evaluationCSS } from "src/themes/evaluation";
import { spacing } from "src/themes/spacing";
import { captionRegularCSS } from "src/components/Typography/Typography";
import { shouldForwardProp } from "src/themes/config";

type Props = {
  className?: string;
  noPaddingRight?: boolean;
  left: React.ReactNode;
  right: React.ReactNode;
};

export const appbarHeight = 48;

const Container = styled(AppBar)`
  ${captionRegularCSS}
  top: 0px;
  min-height: ${appbarHeight}px;
  ${evaluationCSS.level1}
  & .MuiAppBar-colorPrimary {
    color: #000;
  }
`;

const Content = styled(Toolbar, {
  shouldForwardProp,
})<{ $noPaddingRight: boolean }>`
  ${captionRegularCSS}
  min-height: 48px;
  background-color: transparent;
  padding-left: ${spacing.small5};
  ${evaluationCSS.level1}
  ${({ $noPaddingRight }) =>
    $noPaddingRight
      ? css`
          padding-right: 0px;
        `
      : css`
          padding-right: ${spacing.small5};
        `}
`;

const MaterialAppbar = (props: Props) => {
  const { className, noPaddingRight, left, right } = props;
  return (
    <Container className={className} position="absolute">
      <Content $noPaddingRight={Boolean(noPaddingRight)}>
        <Box display="flex" flex="1">
          {left}
        </Box>
        <Box>{right}</Box>
      </Content>
    </Container>
  );
};

export default MaterialAppbar;
