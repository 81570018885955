import _ from "lodash";
import React from "react";
import styled from "@emotion/styled";

import StepIndicatorItem, {
  Props as StepIndicatorItemProps,
} from "src/components/Indicator/StepIndicatorItem";
import { palette } from "src/themes/palette";

type Props = {
  data: StepIndicatorItemProps[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${palette.bluegrey.light};
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StepIndicator = (props: Props) => {
  const { data } = props;

  return (
    <Container>
      {_.map(data, (item, index) => {
        if (index === data.length - 1) {
          return <StepIndicatorItem key={index} {...item} />;
        }
        return (
          <React.Fragment key={index}>
            <StepIndicatorItem {...item} />
            <Divider />
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default StepIndicator;
