import React from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialDialog from "src/components/Dialog/MaterialDialog";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import BottomButton from "src/components/Button/BottomButton";
import {
  Body1RegularPre,
  H3BoldSpan,
} from "src/components/Typography/Typography";

import MaterialIcon from "src/components/Icon/MaterialIcon";

type Props = {
  description: string;
  onConfirm: () => void;
} & WithTranslation;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(MaterialIcon)`
  margin-right: 11px;
`;

const Title = styled(H3BoldSpan)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${palette.darkgrey.dark};
`;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const InvalidInvitedMemberDialog = (props: Props) => {
  const { description, onConfirm } = props;

  return (
    <MaterialDialog
      size={"medium"}
      HeaderComponent={
        <Header>
          <Title>
            <CheckIcon name="cancel" fontSize={30} color={palette.red.main} />
            {"유효하지 않은 초대장입니다."}
          </Title>
          <Description>{description}</Description>
        </Header>
      }
      FooterComponent={
        <BottomButton
          buttonName={"확인"}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: onConfirm,
            isLoading: false,
          }}
        />
      }
    />
  );
};

export default withTranslation()(InvalidInvitedMemberDialog);
