import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import MaterialTextField from "src/components/TextField/MaterialTextField";
import BottomButton from "src/components/Button/BottomButton";
import MaterialButton from "src/components/Button/MaterialButton";
import useLoading from "src/hooks/useLoading";
import {
  H3Bold,
  Body1Regular,
  Body1RegularPre,
} from "src/components/Typography/Typography";

export type Props = {
  className?: string;
  onSubmit: (email: string) => Promise<void>;
} & WithTranslation;

type FormStates = {
  email: string;
};

type FormErrorStates = {
  errors: { [key in keyof FormStates]: string };
};

type States = FormStates & FormErrorStates;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(H3Bold)``;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const Content = styled(Body1Regular)`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${palette.darkgrey.main};
`;

const SetAccountPasswordForm = (props: Props) => {
  const { className } = props;
  const [state, setState] = useState<States>({
    email: "",
    errors: {
      email: "",
    },
  });
  const disabledPassword = !state.email;

  const onEmailChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setState((prevState) => {
        return {
          ...prevState,
          email: value,
        };
      });
    },
    [],
  );

  const { isLoading: isSubmitLoading, wrapperLoading: submit } = useLoading({
    func: async () => {
      const { email: password } = state;
      if (disabledPassword) {
        return;
      }
      try {
        await props.onSubmit(password);
      } catch (error: any) {
        setState((prevState) => {
          return {
            ...prevState,
            errors: { email: error.message },
          };
        });
      }
    },
    deps: [state],
  });

  return (
    <MaterialForm
      className={className}
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>계정 이메일 입력하기</Title>
          <Description>코다에 가입했던 계정의 이메일을 입력하세요.</Description>
        </Header>
      }
      ContentComponent={
        <Content>
          <MaterialTextField
            label={"이메일"}
            autoFocus={true}
            placeholder={"satoshi@kodax.com"}
            style={{
              marginBottom: spacing.medium2,
            }}
            type="text"
            onChange={onEmailChangeText}
            onEnter={submit}
            helperText={state.errors.email}
            size={"large"}
            value={state.email}
          />
        </Content>
      }
      FooterComponent={
        <BottomButton
          buttonName={"다음"}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: submit,
            disabled: disabledPassword,
            isLoading: isSubmitLoading,
          }}
        />
      }
    />
  );
};

export default withTranslation()(SetAccountPasswordForm);
